import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SelectView from '../views/SelectView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/select',
    name: 'select',
    component: SelectView
  },
  {
    path: '/external/:id',
    name: 'external',
    component: () => import(/* webpackChunkName: "external" */ '../views/ExternalView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path: '/news/:id',
    name: 'newsDetail',
    props: true,
    component: () => import(/* webpackChunkName: "newsDetail" */ '../views/NewsDetailView.vue')
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offers" */ '../views/OffersView.vue')
  },
  {
    path: '/offers/:id',
    name: 'offersDetail',
    props: true,
    component: () => import(/* webpackChunkName: "offersDetail" */ '../views/OffersDetailView.vue')
  },
  {
    path: '/emergency',
    name: 'emergency',
    component: () => import(/* webpackChunkName: "emergency" */ '../views/EmergencyView.vue')
  },
  {
    path: '/contact',
    name: 'contactList',
    component: () => import(/* webpackChunkName: "contactList" */ '../views/ContactList.vue')
  },
  {
    path: '/contact/:id',
    name: 'contactDetail',
    component: () => import(/* webpackChunkName: "contactDetail" */ '../views/ContactView.vue')
  },
  {
    path: '/pharmacy',
    name: 'pharmacy',
    component: () => import(/* webpackChunkName: "pharmacy" */ '../views/PharmacyView.vue')
  },
  {
    path: '/nightduty',
    name: 'nightduty',
    component: () => import(/* webpackChunkName: "nightduty" */ '../views/NightDutyView.vue')
  },
  {
    path: '/more',
    name: 'more',
    component: () => import(/* webpackChunkName: "more" */ '../views/AccordeonView.vue')
  },
  {
    path: '/more/:section',
    name: 'moreSection',
    component: () => import(/* webpackChunkName: "moreSection" */ '../views/AccordeonView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order" */ '../views/OrderView.vue')
  },
  {
    path: '/order/confirmation',
    name: 'orderConfirmation',
    component: () => import(/* webpackChunkName: "orderConfirmation" */ '../views/OrderConfirmationView.vue')
  },
  {
    path: '/storecard',
    name: 'starecard',
    component: () => import(/* webpackChunkName: "order" */ '../views/StoreCardView.vue')
  },
  {
    // use :to tag for absolute paths.
    path: "/http*",
    beforeEnter: to => {
      document.location = to.fullPath.substring(1);
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
