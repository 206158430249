import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import {getApoId, setApoIdFromUrl} from "@/lib/user-data";
import {loadData} from "@/lib/apo-data";
import {isInApp} from "@/lib/app-api";

Vue.config.productionTip = false

async function main() {
  setApoIdFromUrl();

  if (!getApoId()) {
    window.location = '#/select'
  } else {
    try {
      await loadData(getApoId());
    } catch(e) {
      if(e.message === '404'){
        window.location = '#/select'
      }
    }
  }

  new Vue({
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')

  Vue.mixin({
    methods: {
      isExternalContent: link => {
        return link && (link.startsWith('tel:') || link.startsWith('mailto:') || link.startsWith('sms:') || link.startsWith('http:') || link.startsWith('https:'))
      }
    }
  })
}

window.main = main;

if(!isInApp()) {
  main();
} else {
  window.addEventListener("flutterInAppWebViewPlatformReady", main);
}

