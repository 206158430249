<template>
  <v-main>
    <v-container>
      <LogoView :logo="logo" />
      <v-row dense>
        <v-col cols="12" sm="8" key="teaser" >
          <v-carousel hide-delimiters height="180px" :show-arrows="teasers.length > 1">
            <v-carousel-item v-for="teaser in teasers" :key="teaser.label">
              <v-card>
                <v-img
                    :src="teaser.image"
                    class="white--text align-end justify-center"
                    height="180px"
                    style="background-color: var(--main-color)"
                >

                  <v-card-title class="text-subtitle-1 pa-1 justify-center" style="background-color: rgba(0,0,0,0.5)" v-text="teaser.label"></v-card-title>
                </v-img>
              </v-card>
            </v-carousel-item>
          </v-carousel>

        </v-col>

        <v-col
            v-for="card in tiles"
            :key="card.title"
            cols="6"
            sm="4"
            :class="{'d-none d-sm-block': card.breakpoint === 'md'}"
        >
          <v-card :to="card.href">
            <v-img
                :src="card.icon"
                class="white--text align-end justify-center"
                height="180px"
                contain
                style="background-color: var(--main-color)"
            >

              <v-card-title class="text-subtitle-1 justify-center pa-1" v-text="card.label"></v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>


import {printAllUserData} from "@/lib/user-data";
import {getData, getLogo, hasOffers} from "@/lib/apo-data";
import LogoView from "@/components/LogoView";

export default {
  components: {LogoView},
  data: () => {
    if(`${document.location.href}`.includes('localStorage')) {
      printAllUserData();
    }
    const data = getData('home');
    if(!hasOffers()) {
      data.tiles = data.tiles.filter(t => t.href !== '/offers')
    }
    return {
      logo: getLogo(),
      ...data
    }
  }
}
</script>
