export function requestCameraPermission() {
    if(window.flutter_inappwebview) {
        return Promise.resolve(window.flutter_inappwebview.callHandler('requestCameraPermission'));
    }
    return Promise.resolve();
}

export function isInApp() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const app = urlParams.get('app');
    return app === 'true';
}
