<template>
  <v-bottom-navigation
      :value="value"
      :color="mainColor"
      background-color="#f5f5f5"
      grow
      rounded
      @change="change"
  >

    <v-btn to="/"  height="auto">
        <span>Start</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>


      <v-btn v-if="showNews" to="/news" height="auto">
        <span>Neuigkeiten</span>
        <v-badge color="red" :content="unreadNews" :value="unreadNews"><v-icon>mdi-file</v-icon></v-badge>
      </v-btn>

    <v-btn to="/emergency" height="auto">
      <span>Notfall</span>

      <v-icon>mdi-ambulance</v-icon>
    </v-btn>

    <v-btn  to="/pharmacy" height="auto">
      <span>Apotheke</span>

      <v-icon>mdi-mortar-pestle-plus</v-icon>
    </v-btn>
    <v-btn  to="/more" height="auto">
      <span>Mehr</span>

      <v-icon>mdi-dots-horizontal</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import {getMainColor, getNews, hasNews} from "@/lib/apo-data";
import {isNewsRead} from "@/lib/user-data";

async function getUnredNews() {
  if(hasNews()) {
    const news = await getNews();
    return news.feed.filter(item => {
      return !isNewsRead(item.id);
    }).length;
  }
}

export default {
  name: "BottomNavigation.vue",
  data: () => ({
    value: 0,
    showNews: hasNews(),
    unreadNews: 0,
    mainColor: getMainColor()
  }),
  created: async function() {
    this.unreadNews = await getUnredNews();
  },
  methods: {
    async change() {
      this.unreadNews = await getUnredNews();
    }
  }
}
</script>

