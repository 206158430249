<template>
  <v-row dense class="justify-center pa-4">
    <v-img :src="logo" max-width="350px"></v-img>
  </v-row>
</template>

<script>

export default {
  components: {},
  props: ['logo'],
}
</script>
